import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import errorScreen from '../../../assets/images/error_screen.jpg'

export const Errata = () => {

    return (
        <Wrapper>
            <div className='content'>
            <img src={errorScreen} alt='yes, it can happen' width="700px" />
            <h2>Published Errata</h2>
            <p>
                Yep, well, no-one's perfect. While I have tried to edit the published works with all the diligence, I can muster, errors, like starsystems through the tightly clenched fist of a 
                cold and merciless star fleet admiral, just keep slipping out.
            </p>
            <h4>Core Rulebook (2nd Ed)</h4>
            <ol>
            <li>p219 - The mishap rule is incorrectly quoted (its actually a lot simpler than this), see the blog "Mishap Simplified".</li>
            <li>p220 - Terraform suit entries are slightly wrong. The suits for the small size category, are actually subject to the miniaturisation rule.</li>
            <li>p222 - Under the heading "Heavy Armour", the text speaks of an armour proficiency, in fact, all heavy armour is subject to the Aegis qualifier, and you suffer its unranked modifier if not qualified</li>
            <li>p223 - Under the heading "Powered Armour", the text speaks of a military mech proficiency, in fact, all powered armour is subject to either the Aegis qualifier or the Vcl-Mech qualifier, and you suffer the unranked modifier if not qualified</li>
            <li>p225 - Under the "Shields" heading the consequences of not having ranks in the Cbt-Melee forte are wrong. The actual cost is that using your re-act to use the shield means that you give up your act, during that turn.</li>
            <li>p225 - Description under the "Inertial" shield heading suggests that its qualified by Cbt-Melee, it should be Cbt-Ranged and the consequences of not having ranks in Cbt-Ranged are not gone into (use of act in deploy and move actions).</li>
            <li>p257 - Description for "Munitions-Hack-Tools" is wrong, what is displayed is that for Materials-Hack-Tools, there should be a description akin to that provided under machinery.</li>
            </ol>
            <h4>Core Rulebook (1st Ed)</h4>
            <p>Apart from the errors detailed below, there are much more serious problems with the First Edition:</p>
            <ol>
            <li>the instructions on how to make a character are too high-level and don't really get into the meat of it, and this is bad, because this is where players should gain their principal understanding of the mechanics;</li>
            <li>explanations around where tools are needed are obtuse and are barriers to understanding;</li>
            <li>the use of skill aspects in the game is very poorly explained to the point where players may not even notice the mechanic (and its really important);</li>
            <li>the striker properties notation, while explained, there is poor organisation around this, which would lead to confusion around its application; and</li>
            <li>a vast number of consistency errors, which would make players wonder which instance has the correct information.</li>
            </ol>
            <ol>
            <li>p49 - xan “ability modifiers” line should be removed.</li>
            <li>p58 - minor formatting issue with "skills (saving throw)".</li>
            <li>p73, p75, p79, p81 - Skill listing reference "Wis/Cha/Per:Study-Enemy 1(ep)", and the one below it, should include the Insight forte, as in "Wis/Cha/Per:Insight:Study-Enemy 1(ep)".</li>
            <li>p104 - In skill list for Combat Medic background "Cha:Persuasion:Inspire" should read "Cha:Persuasion:Inspire 1(fp)"</li>
            <li>p106 - In skill list for Outlaw background "Cha:Persuasion:Spoil" should read "Cha:Persuasion:Spoil 1(fp)" and under the Noble background "Int:Investigation:Gather" should read "Cha:Investigation:Gather"</li>
            <li>p108 - In skill list for Suit background "Cha:Investigation:Inspire" should read "Cha:Persuasion:Inspire 1(fp)"</li>
            <li>p123 - remove Env-Artefact as this is actually covered by the various Tech- aspects</li>
            <li>p131 - when is spelt "whn" in second para under Surveillance</li>
            <li>p176, p182 - references to “Chapter 5: Abilities, Aptitudes & Specialties” should read “Chapter 5: Skills”</li>
            <li>p183 - (1) Focus point formula: "+(fp) = 2 + (Int/Wis/Cha:Focus)" should read "+(fp) = 2 + <b>BM</b>(Int/Wis/Cha:Focus)", (2) remove paragraph beginning with "There are some character features", (3) reference to "Health (Con) focus-skill" should read "Health (Con) forte"</li>
            <li>p200 - in line beginning with “Sometimes terrain can be…” change “move speed” to “movement mode”.</li>
            <li>p200 - few errors under the "Attack" heading: (a) "+ (Dex:Multi-Attack) should read "Dex:Cbt-Melee:Multi-Attack", (b) "target roll" formula should read: "BM(Ability:Forte:Fine-Forte)", (c) wherever "rank" is mentioned after that, it should read "half the rank"</li>
            <li>p202 - under two-weapon fighting, replace "with the + (bonus) operational property" with "and the x(Str,min) is at least 2 below your Str" also replace all of "You cannot include your ability in the based modi!er in the bonus attack, unless that ability is negative." with "The best based modifier for your off-hand weapon is +0."</li>
            <li>p224 - some armour listings show two lines beginning with su2c. The second should always be ss2c.</li>
            <li>p228 - doning and doffing powered armour should be talked about using the "constructed" property detailed on p219, specifically, there is no matter cost.</li>
            <li>p228 - define powered armour more directly as a vehicle, in particular it has a speed (9), although with more expense, better speeds (12 and 16) can be used.</li>
            <li>p230 - cross shield description incorrectly says "is particularly effective against piercing and electric weapons". Yes to prc, but no to elec.</li>
            <li>p230 - missing "electric field" entry. A field designed to protect against electric attacks and effects.</li>
            <li>pp235-251 - Numerous formatting issues and element errors with the weapon entires, Google Sheet character generator, produces correct entries.</li>
            <li>p235 - "vibroblade, hand" has its "su2c" property beginning with "bonus action", when it should read "1(time,bonus)"</li>
            <li>p237 - most rifle materials consumption listings are slightly wrong, as well as some earlier hand-held weapons. All should reflect the calculations made in the Google Character Sheet</li>
            <li>p241 - "flechette, carbine" ss2c and su2c have wrong matter requirements. They read 55(m) and 41(m), but should be 70(m) and 53(m).</li>
            <li>p241 - the "handflechette" ss2c and su2c entries all have wrong matter requirements. They are all 10 times what they should be.</li>
            <li>p242 - the weapon heading "Plasmagun, assault" should read "Plasmagun, battle"</li>
            <li>p291 - Acid Squirter entry "1d8+BM corrosive damage" should read "1d6+BM corrosive damage" and "based modifier from your Combat-Ranged aptitude-skill" is totally wrong, read as: BM is derived from Dex:Cbt-Ranged:Wpn-Spray"</li>
            <li>p292 - entries under the "Claws" cyberware show elements as 2(pen,sls) for example, this should read 2(pen), the 1(reach) element should read 2(reach)</li>
            <li>p295 - lightreception entries after the first incorrectly refer to magnetoreception.</li>
            <li>p299 - under the Reflexes cyberware the reference to "+2(apt:Dex, Save)" should read "+2(Dex:Save)".</li>
            <li>p304 - geneware ability boost incorrectly shows mishap damage on all but the first item.</li>
            <li>p304 - geneware acid gland incorrectly shows effective range as 2 metres in text, should be 3 metres.</li>
            <li>p325 - shuttles and ramblers have 0fp. Everything that is ai-controlled must have at least 1(fp).</li>
            <li>p328 - discussion on the Runboat Scouts price should say: "under 14 million".</li>
            <li>p330 - armour scout's weapons should have damage as 3d12 and 3d6, respectively.</li>
            <li>p338 - Safari system liner, Sensors entry should start with "+0(init)".</li>
            <li>p349 - adaption conjugate propertis shoild be +/-1(adapt,...) for the 2(fp) version and +/-2(adapt,...) for the 3(fp) version.</li>
            <li>p355, p393 - the deafness conjugate currently shows 10(range), this should be 100(range).</li>
            <li>p391 - reference to the aspects Env-Bio-Urban and Env-Bio-Wilderness, should not have the word Bio in there. Also should be Tech-Thot, not Tech-Thotian.</li>
            </ol>

            <p>If you would like to report any other errata, or just ask some questions, tell your navigator to take you to: <a href="https://discord.gg/VmB3hH5tTu">https://discord.gg/VmB3hH5tTu</a></p>

            <h4>Other things to consider</h4>
            <ol>
              <li>Monastics with Machinery or Robotics skill should also have access to Vital-Harm under those skills</li>
              <li>Offhand-Weapon skill - under melee and ranged, allows you to use its BM to provide attack and damage bonuses, superceded by tha ambidexterity enhancement</li>
            </ol>

            </div>
        </Wrapper>
    )
}